function previousAlert() {
  var current = $('.alert-msg.active');
  if (current.prev().siblings().size() > 0) {
    current.prev('.alert-msg').removeClass('unactive');
    current.prev('.alert-msg').addClass('active');
    current.removeClass('active');
    current.addClass('unactive');
  }
  if (!$('.alert-prev').hasClass('disabled')) {
    $('.alert-next').removeClass('disabled');
    if (current.prev().prev().siblings().size() == 0) {
      $('.alert-prev').addClass('disabled');
    } else {
      $('.alert-prev').removeClass('disabled');
    }
  }
}

function nextAlert() {
  var current = $('.alert-msg.active');
  if (current.next().siblings().size() > 0) {
    current.next('.alert-msg').removeClass('unactive');
    current.next('.alert-msg').addClass('active');
    current.removeClass('active');
    current.addClass('unactive');
  }
  if (!$('.alert-next').hasClass('disabled')) {
    $('.alert-prev').removeClass('disabled');
    if (current.next().next().siblings().size() == 0) {
      $('.alert-next').addClass('disabled');
    } else {
      $('.alert-next').removeClass('disabled');
    }
  }
}

function loopAlert() {
  var current = $('.alert-msg.active');
  if (current.next().siblings().size() == 0) {
    $('.alert-prev').addClass('disabled');
    $('.alert-next').removeClass('disabled');
    $('.alert-msg.active').addClass('unactive');
    $('.alert-msg.active').removeClass('active');
    $('.alert-msg:first-child').addClass('active');
    $('.alert-msg:first-child').removeClass('unactive');
  } else {
    nextAlert();
  }
}

$(function () {
  if ($('.alert-msg').length > 1) {
    // Verifica se há mais do que um alerta e ativa a seta para o seguinte
    $('.alert-next').removeClass('disabled');
    
    // Passa para o próximo alerta ao fim de 6s
    var alertInterval = setInterval(loopAlert, 6000);
    
    // Quando o utilizador carrega numa seta, desativa o slider automático
    $('.alert-prev, .alert-next').click(function () {
      clearInterval(alertInterval);
    });
  }
});

/*!
 * bootstrap-fileinput v4.3.7
 * http://plugins.krajee.com/file-input
 *
 * Font Awesome icon theme configuration for bootstrap-fileinput. Requires font awesome assets to be loaded.
 *
 * Author: Kartik Visweswaran
 * Copyright: 2014 - 2017, Kartik Visweswaran, Krajee.com
 *
 * Licensed under the BSD 3-Clause
 * https://github.com/kartik-v/bootstrap-fileinput/blob/master/LICENSE.md
 */
(function ($) {
    "use strict";

    $.fn.fileinputThemes.fa = {
        fileActionSettings: {
            removeIcon: '<i class="icon-cross"></i>',
            uploadIcon: '<i class="icon-cloud-upload"></i>',
            zoomIcon: '<i class="icon-circle-plus"></i>',
            dragIcon: '<i class="icon-pause"></i>',
            indicatorNew: '<i class="icon-flag text-warning"></i>',
            indicatorSuccess: '<i class="icon-check text-success"></i>',
            indicatorError: '<i class="icon-cross text-danger"></i>',
            indicatorLoading: '<i class="icon-loader text-muted"></i>'
        },
        layoutTemplates: {
            fileIcon: '<i class="icon-clipboard kv-caption-icon"></i> '
        },
        previewZoomButtonIcons: {
            prev: '<i class="icon-arrow-left fa-lg"></i>',
            next: '<i class="icon-arrow-right fa-lg"></i>',
            toggleheader: '<i class="icon-reply"></i>',
            fullscreen: '<i class="icon-cross"></i>',
            borderless: '<i class="icon-link"></i>',
            close: '<i class=" icon-circle-cross"></i>'
        },
        previewFileIcon: '<i class="icon-file"></i>',
        browseIcon: '<i class="icon-folder"></i>',
        removeIcon: '<i class="icon-circle-cross"></i>',
        cancelIcon: '<i class="icon-reply"></i>',
        uploadIcon: '<i class="icon-cloud-upload"></i>',
        msgValidationErrorIcon: '<i class="icon-bell"></i> '
    };
})(window.jQuery);
